<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.key") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="card p-4">
        <div
          class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.stamp") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ key.stamp }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.estate_name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ estate.name }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.address") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                getAddress(estate.buildings, key.buildingId)
              }}</span>
            </div>
          </div>
        </div>
        <hr class="my-4 border-slate-300" />
        <div class="flex justify-end">
          <form-button
            :command="
              () =>
                route.params.estateId
                  ? router.push(`/estates/${estate.id}/keys/${key.id}/edit`)
                  : router.push(`/keys/${key.id}/edit`)
            "
            :disabled="waiting"
            id="profile-edit-button"
            label="edit"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
      <div>
        <h2 class="mb-2">{{ t("headings.loans_returns") }}</h2>
        <div class="card">
          <custom-table
            :fields="fields"
            :items="loans"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="dateBorrowed"
            :ascending="false"
            :sortFields="sortFields"
            @row-click="(id) => router.push(`/keys/${key.id}/loan/${id}`)"
          >
            <template #header>
              <span v-if="!key.loaned" class="flex justify-end"
                ><form-button
                  :command="() => router.push(`/keys/${key.id}/lend`)"
                  id="header-key-lend-button"
                  label="lend_key"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
              <span v-else class="flex justify-end"
                ><form-button
                  :command="() => router.push(`/keys/${key.id}/return`)"
                  id="header-key-return-button"
                  label="key_return"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(dateBorrowed)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(dateReturned)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(lenderId)="{ value }">
              {{ getUserName(value, estateUsers) }}
            </template>
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getUserName } from "../utils/userUtils";

export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const key = computed(() => store.state.key.key);
    const loans = computed(() => store.state.key.loans);
    const estateUsers = computed(() => store.state.user.users);

    const waiting = computed(() => {
      if (store.state.authentication.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.key.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "borrower",
        label: "borrower_name",
      },
      {
        key: "lender",
        label: "lender_name",
      },
      {
        key: "dateBorrowed",
        label: "date_borrowed",
      },
      {
        key: "dateReturned",
        label: "date_returned",
      },
    ]);
    const searchFields = ref(["borrower", "dateBorrowed", "dateReturned"]);
    const sortFields = ref(["borrower", "dateBorrowed", "dateReturned"]);

    const getAddress = (buildings, buildingId) => {
      if (!buildings?.length || !buildingId) return;
      const building = buildings.find((building) => building.id === buildingId);
      return `${building.address}, ${building.postalCode} ${building.city}`;
    };

    onMounted(() => {
      store.dispatch("key/getKey", route.params.keyId);
    });

    watch(
      () => key.value,
      (key) => {
        if (key.id) {
          store.dispatch("key/getKeyLoans", route.params.keyId);
          store.dispatch("user/getEstateUsers", key.estateId);
          store.dispatch("estate/getEstate", key.estateId);
        }
      }
    );

    return {
      company,
      estate,
      estateUsers,
      fields,
      getAddress,
      getLocaleDate,
      getUserName,
      key,
      loans,
      locale,
      route,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
